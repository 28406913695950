
import apiBaseUrl from "./global";

const VIDEO_API_URL = apiBaseUrl+"/video/view";

const API_URL =apiBaseUrl+"/api/lessons/";

const statusUrl = apiBaseUrl+"api/lessons/";


class LessonService
{
    async retrieveVideoUrl(vid){

        const response = await fetch (VIDEO_API_URL,{
            method:'POST',
            credentials: 'include',
            body:JSON.stringify(vid),
            headers: {
              'Content-Type': 'application/json'
            }
          }).catch((error) =>{
            console.error("Fetch Video Key failed",error);
            return response.status;
          });
          return response.json();
    }

    
    async updateLessonStatus(videoStatusDTO)
    {
      const response = await fetch (statusUrl+"setVideoStatus",{
        method:'POST',
        credentials: 'include',
        body:JSON.stringify(videoStatusDTO),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch((error) =>{
        console.error("Update lesson status failed",error);
        return response.status;
      });
      return response.json();
    }

    async getLessonStatus(videoStatusDTO)
    {
      const response = await fetch (statusUrl+"getVideoStatus",{
        method:'POST',
        credentials: 'include',
        body:JSON.stringify(videoStatusDTO),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch((error) =>{
        console.error("Fetch lesson status",error);
        return response.status;
      });
      return response.json();
    }

    async createLesson(data)
    {
        console.log(data);
        const response = await fetch(API_URL+"createLesson",{
            method:'POST',
            credentials: 'include',
            body:JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
              }
          }).catch((error) =>{
            console.error("Create lesson failed",error);
            return response.status;
          });
          return response.json();
    }

    async updateLesson(data)
    {
        console.log("update Lesson " + JSON.stringify(data));
        const response = await fetch(API_URL,{
            method:'POST',
            credentials: 'include'
          });
          return response.json();
    }

    async deleteLesson(data)
    {
        console.log("delete Lesson  " + JSON.stringify(data));
        const response = await fetch(API_URL,{
            method:'POST',
            credentials: 'include'
          });
          return response.json();
    }

}


export default new LessonService();