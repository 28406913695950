import React from "react";

export default function HomeCard({imageName, subtitle, text}) {

    return (
        <div className="card mb-3 bg-light">
            <div className="row g-0">
                <div className="col-md-5">
                    <div style={{ width: '300px', height: '300px', maxWidth:'100%', overflow: 'hidden' }}>
                        <img
                            className="img-fluid"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            src={imageName + '.jpg'}
                            alt={imageName}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card-body" style={{ maxHeight: '300px', overflowY: 'auto', paddingLeft: '15px' }}>
                        <h5 className="card-title text-black">{subtitle}</h5>
                        <p className="card-text">{text}</p>
                    </div>
                </div>
            </div>
        </div>
    );

}


/*
return (
    <div className="pt-1 px-3 pt-md-3 text-white">
        <div className="my-3 py-1">
            <div className="shadow-sm mx-auto mb-2">
                <img style={{ width: 200, height: 300 }}  src={imageName+".jpg"}/>
            </div>
            <h6>{subtitle}</h6>
            <p className="lead text-black">{text}</p>
        </div>
    </div>
);
*/