import apiBaseUrl from "./global";

const API_URL = apiBaseUrl + "/api/auth/";

class AuthService {
    login(username, password) {
        return fetch(API_URL + "signin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, password}),
            credentials: 'include', // To send cookies along with the request
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("HERE in fetch");
                console.log(data);
                // Process the response data as needed
                return data;
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                throw error; // Propagate the error further if needed
            });
    }


    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return fetch(API_URL + "signup", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                email,
                password,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Assuming the response is JSON, adjust if it's different
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                throw error; // Propagate the error further if needed
            });
    }

}

export default new AuthService();
