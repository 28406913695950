import React, { useState, useEffect } from 'react';
import LessonList from "./lesson-list.component";
import CourseService from "../../services/course-service";
import Lesson from "./lesson.component";
import { useParams } from 'react-router-dom';
import LessonNavigation from "./lesson-navigation.component";


export default function CourseHome(props) {

    const [course, setCourse] = useState(null);
    const [lessonIndexShown, setLessonIndexShown] = useState(0);
    const [lessonPanelSize, setLessonPanelSize] = useState("justify-content-center col-md-auto");
    const [lessons, setLessons] = useState(null);
    const [loading,setLoading] = useState(true);

    let params = useParams();
    console.log(`Props are ${JSON.stringify(props)}`);
    console.log(`Parms are : ${JSON.stringify(params)}`);

    let courseName = params.courseName;

    useEffect(() => {
        const fetchData = async () => {
            CourseService.retrieveCourseByName(courseName).then((data) => {
                console.log("set fetch individual course" + JSON.stringify(data));
                setCourse(data);
                console.log("set lessons" + JSON.stringify(data.lessons));
                setLessons(data.lessons);
                setLoading(false);
            })
                .catch(error => {
                    console.log(error);
                });
        };
        fetchData();


    }, []);


    if (params.lessonIndex !== undefined && params.lessonIndex != lessonIndexShown) {
        setLessonIndexShown(params.lessonIndex);
    }
    let lesson = null;
    let lessonName = null;
    if (course != null) {
        console.log("lesson id is " + lessonIndexShown);
        lesson = course.lessons[lessonIndexShown];
        console.log("found" + JSON.stringify(lesson));
        lessonName = lesson.displayName;
        if (typeof lesson === 'undefined') {
            lesson = course.lessons[0];
        }
    }
    return (
        <div>
            <div className="row">
                <div className="col-2">

                </div>
                <div className="col">
                    {courseName} > {lessonName}
                </div>
            </div>
            <div className="row row-cols-2">
                <div className="col col-lg-2">
                    {course ?
                        <LessonList lessons={course.lessons} hidePanel={false} />
                        : <p>Loading...</p>}
                </div>
                {lesson ?
                <div className={lessonPanelSize}><Lesson lesson={lesson}/></div> : null}
            </div>
            {course
            ? <LessonNavigation lessons={lessons} lessonId={lessonIndexShown}/>: null}
        </div>
    );
}




