// AuthProvider.js
import React, { useState } from 'react';
import AuthContext from './AuthContext';
import AuthService from '../../services/auth.service';
import log from 'loglevel';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem("user"));
    log.setLevel('debug');

    const getUser = () =>
    {
        return JSON.parse(localStorage.getItem("user"));
    }
    const register = (username, email, password) => {
        return AuthService.register(username, email, password)
            .then((data) => {
                console.log("AUTH PROVIDER" + data);
                console.log(data);
                if (data) {
                    setUser(JSON.stringify(data));
                    localStorage.setItem("user", user );
                }
            })
            .catch((error) => {
                log.error(error)
            }).finally(
                (data) =>
                {
                    log.info(data);
                }
            );
    };

    const login = (username, password) => {
        return AuthService.login(username, password)
            .then((data) => {
                console.log("WHY");
                console.log(data);
                if (data) {
                    localStorage.setItem("user",JSON.stringify(data));
                    setUser(data);
                }
            })
            .catch((error) => {
                log.error(error)
            })
    };

    const logout = () => {
        console.log("logout called");
        AuthService.logout();
        setUser(null);
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                register,
                login,
                logout,
                getUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
