import React, { useContext } from "react";
import { Navigate } from 'react-router-dom';
import AuthContext from "./auth/AuthContext";

const Profile = () => {
    const { getUser } = useContext(AuthContext);

    console.log(getUser());

    const user = getUser();

    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <header className="jumbotron">
                <h3>
                    <strong>{user.username}</strong> Profile
                </h3>
            </header>

            <p>
                <strong>Id:</strong> {user.id}
            </p>
            <p>
                <strong>Email:</strong> {user.email}
            </p>
            <strong>Authorities:</strong>
            <ul>
                {user.roles &&
                    user.roles.map((role, index) => <li key={index}>{role}</li>)}
            </ul>
        </div>
    );
};

export default Profile;