import React, {useContext} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthProvider from "./auth/AuthProvider"
import AuthContext from "./auth/AuthContext";

const RegisterSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, "Username must be at least 3 characters")
        .max(20, "Username must not exceed 20 characters")
        .required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(40, "Password must not exceed 40 characters")
        .required("Password is required"),
});




const Registration = ({ message}  ) => {

    const { register } = useContext(AuthContext);

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />

                <Formik
                    initialValues={{
                        username: "",
                        email: "",
                        password: "",
                    }}
                    validationSchema={RegisterSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        register(values.username, values.email, values.password)
                            .then((data) => {
                                console.log(data);
                            })
                            .catch(() => {
                                console.log("failed");
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="username"
                                />
                                <ErrorMessage name="username" component="div" className="alert alert-danger" role="alert" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="email"
                                />
                                <ErrorMessage name="email" component="div" className="alert alert-danger" role="alert" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field
                                    type="password"
                                    className="form-control"
                                    name="password"
                                />
                                <ErrorMessage name="password" component="div" className="alert alert-danger" role="alert" />
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit" disabled={isSubmitting}>
                                    Sign Up
                                </button>
                            </div>

                            {message && (
                                <div className="form-group">
                                    <div className={message ? "alert alert-success" : "alert alert-danger"} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Registration;