import React from 'react';
import { useParams,useNavigate } from "react-router-dom";

export default function LessonNavigation ({lessons})
{

    let params = useParams();

    console.log("I am lessons", lessons, params.lessonIndex);
    const navigate = useNavigate();
    const indexOrder = params.lessonIndex;

    const goToNext = () =>{ return findMinMax(lessons,indexOrder,false);}
    const goToPrevious = () => { return findMinMax(lessons,indexOrder,true);}


    return (
    <div>
        <button className="btn btn-primary" onClick={() => navigate(goToPrevious(),{relative:"path"})}> Previous</button>
        <button className="btn btn-primary" onClick={() => navigate(goToNext(),{relative:"path"})}>Next</button>
    </div>
    );

  }

function findMinMax(lessons,indexOrder,isMin)
{
    let fragment = "../";

    console.log("index order is " + indexOrder);

    if(lessons == null || lessons.length == 0 )
        return fragment + 0;

    if(isMin){
        if(0 < indexOrder)
            indexOrder--;
    }
    else{
        if(lessons.length-1 > indexOrder)
            indexOrder++;
    }



    console.log("nextLesson is " + indexOrder);

    return fragment+indexOrder;
}
