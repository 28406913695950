import React from "react";
import {Link} from "react-router-dom";
import image from "../../images_for_react/basics.jpg";

export default function CourseCard({course})
{
    let style = {"maxWidth": "540px"};

    return (
        <div className="card mb-3" style={style}>
            <div className="row g-0">
                <div className="col-md-4">
                <img src={image} className="img-fluid rounded-start" alt="..."/>
                </div>
                <div className="col-md-8">
                <div className="card-body">
                    <h5 className="card-title"><Link key={course.courseId} to={course.courseName}>{course.displayName}</Link></h5>
                    <p className="card-text">{course.subtitle}</p>
                    <p className="card-text"><small className="text-body-secondary">{course.description}</small></p>
                    <Link className="btn btn-primary" key={course.courseId} to={course.courseName}>Go to course</Link>
                </div>
                </div>
            </div>
        </div>
        );
    
}