import React from 'react';
import { Formik, Field, Form } from 'formik';
import lessonService from '../../../../services/lesson-service';
import courseService from '../../../../services/course-service';
import Select from 'react-select'

export default class LessonCrud extends React.Component{

    constructor(props)
    {
        super(props);
        this.state = {};
    }

    componentDidMount(){
          courseService.retrieveCourses().then(data =>{
            console.log(data);
            this.setState({courses:data});
          });
    }
    mapCourseData(courses)
    {

        var options = courses.map(course =>
             { return {value:course.courseId,label:course.courseName}}
        );

        return options;
    }
    render() {
      let {courses} = this.state;
      const handleChange = (selectedOption) => {
        console.log(`Option selected:`, selectedOption);
        this.setState({courseId:selectedOption.value});
      };
    if(typeof courses !== 'undefined')
      return (
        
          <div>
      <h1>Sign Up</h1>
      <Formik
        initialValues={{
          lessonName: '',
          courseId:''
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          lessonService.createLesson(values);
        }}
      >
        <Form>
          <label htmlFor="lessonName">Lesson Name</label>
          <Field id="lessonName" name="lessonName" placeholder="Lesson Name" />
          <Field as="select" name="courseId">
            <option value=""></option>
            {courses.map((course,index) => (
              <option value={course.courseId}>{course.courseName}</option>
            ))
            }
        </Field>
          <button type="submit">Submit</button>
        </Form>
      </Formik>
    </div>
      )
      else
      return null;
    }
}



