import React, {useContext} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthProvider from "./auth/AuthProvider"
import AuthContext from "./auth/AuthContext";

const LoginForm = () => {
    const initialValues = {
        email: '',
        password: '',
    };

    const { user, isLoggedIn, login, logout } = useContext(AuthContext);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
        try {
            const userData = await login(values.username, values.password);
            // Handle successful login (e.g., save user data to state, localStorage)
            console.log('Login successful:', userData);
        } catch (error) {
            setFieldError('password', error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{username:"test",email:"test@aol.com",password:"test12"}}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div>
                        <label htmlFor="username">Username:</label>
                        <Field type="text" name="username"/>
                        <ErrorMessage name="username" component="div" />
                    </div>

                    <div>
                        <label htmlFor="email">Email:</label>
                        <Field type="email" name="email"/>
                        <ErrorMessage name="email" component="div" />
                    </div>

                    <div>
                        <label htmlFor="password">Password:</label>
                        <Field type="password" name="password"/>
                        <ErrorMessage name="password" component="div" />
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
