import React from 'react';
import { Formik, Field, Form } from 'formik';

import courseService from '../../../../services/course-service';

export default class CreateCourse extends React.Component{

    constructor(props)
    {
        super(props);
    }

    render() {

    return (
        <div>
    <h1>Sign Up</h1>
    <Formik
      initialValues={{
        courseName: ''
      }}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
        courseService.createCourse(values);
      }}
    >
      <Form>
        <label htmlFor="courseName">Course Name</label>
        <Field id="courseName" name="courseName" placeholder="Course Name" />
        <button type="submit">Submit</button>
      </Form>
    </Formik>
  </div>
    )
    }
}



