import React from 'react';

import CourseCard from './course.card.component';

export default function CourseList({ courses }) {

    let detailsPanel;

    console.log(courses);

    let courseList = courses.map(course => 
        <div key={course.courseId} className="row">
                <CourseCard key={course.courseId} course={course}/>
        </div>);

    detailsPanel = (
        <div className="justify-content-center">
            {courseList}
        </div>
    );

    return detailsPanel;
}


