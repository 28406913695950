import React, { useState, useEffect } from 'react';

import CourseService from "../../services/course-service";
import CourseList from "./course-list.component";

export default function CourseMainPage()
{
    const [courses, setCourses] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
          CourseService.retrieveCourses().then((data) =>{
              console.log("set data" + data);
              setCourses(data);
          })
          .catch(error => {
              console.log(error);
          });
      };
      fetchData();
    }, []);

    if(courses == null)
        return null;

    return (
        <CourseList courses={courses}/>
    );

}     
