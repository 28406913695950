import React, { useState } from 'react';
import { Link } from "react-router-dom";

export default function LessonList({lessons,hidePanel}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="p-3">
            <hr></hr>
            <div className="row">
                <div className={`col-2 sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    <button onClick={toggleExpand} className="toggle-button">
                        <div className={`vertical-bar ${isExpanded ? 'expanded' : 'collapsed'}`}>
                            <div className="chevron">{isExpanded ? "▶" : "◀"}</div>
                        </div>
                    </button>
                </div>
                <div className={`col content ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    {(isExpanded ?   <ul className="list-group">
                        {lessons.map((lesson,index) => <li key={index}><Link to={"./"+(index)}>{lesson.displayName}</Link></li>)}
                    </ul>  : null)}
                </div>
            </div>
        </div>);



}

