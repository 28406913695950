import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AdminHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div>
        <div className="container">
          <Link to="/admin/courses" className="nav-link">
            Administer your courses
          </Link>

        </div>
        <div className="container">
          <Link to={"/CourseEnrollmentAdmin"} className="nav-link">
            Administer your course enrollment
          </Link>

        </div>
        <div className="container">
          <Link to={"/admin/lessons"} className="nav-link">
            Administer your lessons
          </Link>

        </div>
        <div className="container">
          <Link to={"/UserLessonStatusAdmin"} className="nav-link">
            Administer your users' lesson status
          </Link>
        </div>
      </div>
    );
  }
}
