import React, { Component } from "react";

import CreateCourse from "./course-crud/create-course";

export default class CourseAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    /*
    CreateCourse.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
    */
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>Manage your courses</h3>
          <CreateCourse></CreateCourse>

        </header>
      </div>
    );
  }
}
