// Login.js
import React, { useContext, useState } from 'react';
import AuthContext from './auth/AuthContext';
import LoginForm from "./login-form";
import { Navigate } from 'react-router-dom';
const LoginNext = ({ history }) => {
    const { isLoggedIn, login } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        try {
            await login(username, password);
            history.push('/profile');
            window.location.reload();
        } catch (error) {
            setMessage(error.message || 'Login failed');
            setLoading(false);
        }
    };

    if (isLoggedIn) {
        return <Navigate to="/profile" />;
    }

    return (
        <div className="col-md-12">
            <LoginForm/>
        </div>
    );
};

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default LoginNext;
