
import apiBaseUrl from "./global";

const url = apiBaseUrl+"/api/courses";

class CourseService{

    async retrieveCourses(){
        const response = await fetch(url+"/mycourses",{
            method:'POST',
            credentials: 'include'
          });
          return response.json();
    }

    async retrieveCourseByName(courseName){
        console.log(courseName)
        let data = JSON.stringify({courseName:courseName});
        const response = await fetch(url+"/getCourseInfo",{
            method:'POST',
            credentials: 'include',
            body: data,
            headers: {
                "Content-Type": "application/json"
            }
            });
          return response.json();
    }


    async createCourse(data)
    {
        console.log("Create course " + JSON.stringify(data));
        const response = await fetch(url,{
            method:'POST',
            credentials: 'include'
          });
          return response.json();
    }

    async updateCourse(data)
    {
        console.log("Update course " + JSON.stringify(data));
        const response = await fetch(url,{
            method:'POST',
            credentials: 'include'
          });
          return response.json();
    }

    async deleteCourse(data)
    {
        console.log("delete course " + JSON.stringify(data));
        const response = await fetch(url,{
            method:'POST',
            credentials: 'include'
          });
          return response.json();
    }

}

export default new CourseService();