import LessonService from "../../services/lesson-service";
import ReactPlayer from 'react-player';
import React, { useRef, useState, useEffect } from 'react';
import image from "../../images_for_react/basics.jpg";
export default function Lesson({ lesson }) {
    
    console.log("Lesson setup" + JSON.stringify(lesson));
    
   // if(lesson.content != null && lesson.content.length != 0 && lesson.content[0].content.type ==0)
    var { vhash } = lesson.content[0].content;

    const [showVideo, setShowVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState(false);
    const [thumbnailUrl, setThumbnailUrl] = useState(false);

    const playerRef = useRef();

    useEffect(() => {
        console.log("use effect");
        const fetchData = async () => {
            if (vhash) {
                LessonService.retrieveVideoUrl({ videoId: vhash }).then((data) => {
                    console.log("Lesson url returned" + JSON.stringify(data));
                    setVideoUrl(data.videoUrl);
                //    setThumbnailUrl(data.thumbnail);
                });
            }
            else{
                setVideoUrl(false);
                setThumbnailUrl(false);
            }
        };
        fetchData();
    }, [vhash]);

    console.log("Video url" + videoUrl);
    console.log(`content type ${lesson.content.type}`);
    console.log(JSON.stringify(lesson));
    if (showVideo) {
        return showVideoCard();
    }


    return (
        <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-4">
                    <img key={lesson.id} className="mg-fluid rounded-start" src={thumbnailUrl ? thumbnailUrl : image} alt="thumbnail" />
                </div>
            </div>
            <div className="col-md-8">
                <div className="card-body">
                    <h5 className="card-title">{lesson.displayName}</h5>
                    <p className="card-text">{lesson.description}</p>
                    <a href="#" onClick={handleShowVideoClick.bind(this)} className="btn btn-primary">Watch Video</a>
                </div>
            </div>
        </div>
        );



    function showVideoCard() {
        let videoPlayer = getVideoPlayer();

        return (
            <div>
                <div className="card mb-3">
                    <div className="col-md-8">
                        <div className="card-body">
                            <h5 className="card-title">{lesson.displayName}</h5>
                            <p className="card-text">{lesson.description}</p>
                            {videoPlayer}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function handleShowVideoClick() {
        setShowVideo( showVideo => !showVideo);
    }

    function getVideoPlayer() {
        return (
            <div className='player-wrapper'>
                <ReactPlayer
                    ref={playerRef}
                    controls={true}
                    className='react-player'
                    url={videoUrl}
                    width='100%'
                    height='100%'
                    onPause={() => handleVideoPlayerStop()}
                    onEnded={() => handleVideoPlayerStop()}
                />
            </div>);
    }

    function handleVideoPlayerStop() {
        let time = this.player.getCurrentTime();
        console.log("current time left" + time);
        let floor = Math.floor(time)
        //   LessonService.updateLessonStatus({ videoId: this.props.vid, timeIndex: floor, lessonId: this.props.lessonId });
        //    this.player.seekTo(parseFloat(1.5))
    }
}


/*if(typeof this.props.lesson.content !== undefined && this.props.lesson.content.length){
    let {vid,vhash} = this.props.lesson.content[0];
    let lessonId = this.props.lesson.lessonId;

    LessonService.getLessonStatus({videoId:vid,lessonId:lessonId});
    window.addEventListener("beforeunload", (event) => {
    console.log("I am the 1st one.");
    });*/